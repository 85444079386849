/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../stylesheets/application'

// Allows $ to be accessed from the window. Potentially hacky solution that
// was found here:
// https://stackoverflow.com/questions/58371711/bootstrap-hide-modal-not-working-in-rails-6-using-ajax
import jquery from 'jquery';
if (!window.$ && !window.jquery) window.$ = window.jquery = jquery;

import './bootstrap_custom.js'
import './enable_clipboardable_tooltips.js'

import "@fortawesome/fontawesome-free/css/all.css"

import {} from 'jquery-ujs'
import './data_confirm_modal.js'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
