document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('[data-clipboardable]').forEach(function(el) {
    el.addEventListener("click", function(event) {
      event.preventDefault();
      navigator.clipboard.writeText(event.target.dataset.clipboardable);

      const selector = `#${event.target.id}`;
      $(selector).tooltip('enable');
      $(selector).tooltip('show');
      setTimeout(function () {
        $(selector).tooltip('disable');
      }, 1000)
    });
  });
});
